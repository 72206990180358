import {Box, HStack, Image, Link, Text} from '@chakra-ui/react';
import mandus from '../../static/audits/manuds-security.svg';
import halborn from '../../static/audits/halborn.svg';
import peckshield from '../../static/audits/peckshield.svg';
import Container from '../../layouts/Container';
import { FaArrowRightLong } from 'react-icons/fa6';
import zero3DRight from '../../static/vectors/zero-3d-right.png';
import zero3DLeft from '../../static/vectors/zero-3d-left.png';

const Audits = () => {
  return (
    <Box pb={56}>
      <Container>
        <Box
          w={'100%'}
          h={{base: 'auto', md: '296px'}}
          bg={'#0E0E0E'}
          borderRadius={'2xl'}
          overflow={'hidden'}
          position={'relative'}
          p={{base: 8, md: 0}}
        >
          <Image src={zero3DRight} position={'absolute'} right={0} opacity={0.2} display={{base: 'none', md: 'block'}}/>
          <Image src={zero3DLeft} position={'absolute'} left={0} opacity={0.2} display={{base: 'none', md: 'block'}}/>
          <Box
            display={{base: 'block', md: 'flex'}}
            alignItems={'center'}
            justifyContent={'center'}
            height={'100%'}
          >
            <Box textAlign={{base: 'left', md: 'center'}}>
              <Text fontSize={'28px'} fontWeight={'bold'} mb={{base: 4, md: 12}}>
                Security Audits
              </Text>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={{base: 'flex-start', md: 'center'}}
                mb={{base: 4, md: 12}}
                flexWrap={{base: 'wrap', md: 'unset'}}
              >
                <Link px={6} mx={2} my={{base: 4, md: 0}} href={'#'}>
                  <img src={halborn} alt={'hakborn'} />
                </Link>
                <Link px={6} mx={2} my={{base: 4, md: 0}} href={'https://docs.zerolend.xyz/security/audits'}>
                  <img src={mandus} alt={'mandus'} />
                </Link>
                <Link px={6} mx={2} my={{base: 4, md: 0}} href={'#'}>
                  <img src={peckshield} alt={'peckshield'} />
                </Link>
              </Box>
              <Link
                fontWeight={600}
                mx={{base: 0, md: 'auto'}}
                w={'max-content'}
                display={'block'}
              >
                <HStack>
                  <Link href={"https://docs.zerolend.xyz/security/audits"} target={'_blank'}><Text>View Report</Text></Link>
                  <FaArrowRightLong />
                </HStack>
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Audits;
