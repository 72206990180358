import Container from '../../../layouts/Container';
import { Box, Text, useMediaQuery } from '@chakra-ui/react';
import Era from '../../../static/partners/era.svg';
import Manta from '../../../static/partners/Manta.png';
import Okx from '../../../static/partners/okx.svg';
import Aave from '../../../static/partners/aave.svg';
import RedStone from '../../../static/partners/redstone.png';
import StakeStone from '../../../static/partners/stakestone.png';
import Pyth from '../../../static/partners/pyth.svg';
import LayerZero from '../../../static/partners/LayerZero.svg';
import Gelato from '../../../static/partners/gelato.svg';
import Mute from '../../../static/partners/Mute.png';
import spacefi from '../../../static/partners/Spacefi.png';
import velocore from '../../../static/partners/Velocore.svg';
import vesync from '../../../static/partners/Vesync.svg';
import eZkalibur from '../../../static/partners/Ezkalibur.png';
import mandus from '../../../static/partners/Mundussecurity.svg';
import holstation from '../../../static/partners/holdstation.png';
import liquity from '../../../static/partners/Liquity.svg';
import star from '../../../static/partners/Star-protocol.png';
import bitget from '../../../static/partners/Bitget.svg';
import halborn from '../../../static/partners/halborn.svg';
import chaos from '../../../static/partners/chaos.svg';
import immunefi from '../../../static/partners/immune.png';
import peckshield from '../../../static/partners/peckshield.png';
import spearbit from '../../../static/partners/spearbit.png';
import mountain from '../../../static/partners/mountain.png';

import bisonFund from '../../../static/investors/bisonFund.png';
import blockchainFounders from '../../../static/investors/blockchainFounders.png';
import boxMining from '../../../static/investors/boxMining.png';
import cryptoBanter from '../../../static/investors/cryptoBanter.png';
import cypherCapital from '../../../static/investors/cypherCapital.png';
import ethernity from '../../../static/investors/ethernity.png';
import founderHeads from '../../../static/investors/founderHeads.png';
import GoldenTree from '../../../static/investors/goldenTree.png';
import iangels from '../../../static/investors/iangels.png';
import iba from '../../../static/investors/iba.png';
import krypital from '../../../static/investors/krypital.png';
import morningStar from '../../../static/investors/morningStar.png';
import ozaru from '../../../static/investors/ozaru.png';
import transformCapital from '../../../static/investors/transformCapital.png';
import dvdt from '../../../static/investors/dvdt.png';
import momentum from '../../../static/investors/momentum.png';
import newman from '../../../static/investors/newman.png';
import viaBTCCapital from '../../../static/investors/viaBTCCapital.png';
import woodStock from '../../../static/investors/woodStock.png';

import { useMemo, useState } from 'react';
import DesktopPartners from './DesktopPartners';
import MobilePartners from './MobilePartners';

export interface IIndividualPartner {
  name: string;
  logo: string;
  link: string;
}

const partners: { [keys: string]: IIndividualPartner[] } = {
  Infra: [
    {
      name: 'ZkSync Era',
      logo: Era,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'Manta Network',
      logo: Manta,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'LayerZero',
      logo: LayerZero,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'Pyth',
      logo: Pyth,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'Gelato',
      logo: Gelato,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'Redstone',
      logo: RedStone,
      link: 'https://app.zerolend.xyz',
    },
  ],
  Defi: [
    {
      name: 'Aave',
      logo: Aave,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'Liquity',
      logo: liquity,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'HoldStation',
      logo: holstation,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'Star Protocol',
      logo: star,
      link: 'https://app.zerolend.xyz',
    },
  ],
  "RWAs/LSDs": [
    {
      name: 'Stakestone',
      logo: StakeStone,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'Mountain',
      logo: mountain,
      link: 'https://app.zerolend.xyz',
    },
  ],
  Dexes: [
    {
      name: 'Mute',
      logo: Mute,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'SpaceFi',
      logo: spacefi,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'Velocore',
      logo: velocore,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'vesync',
      logo: vesync,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'eZkalibur',
      logo: eZkalibur,
      link: 'https://app.zerolend.xyz',
    },
  ],
  All: [],
  Miscellaneous: [
    {
      name: 'OKX',
      logo: Okx,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'Bitget',
      logo: bitget,
      link: 'https://app.zerolend.xyz',
    },
  ],
  Audits: [
    /*{
      name: 'Halborn',
      logo: halborn,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'Peckshield',
      logo: peckshield,
      link: 'https://app.zerolend.xyz',
    },*/
    {
      name: 'Mundus Dev ',
      logo: mandus,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'Spearbit by Cantina ',
      logo: spearbit,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'Chaos Labs',
      logo: chaos,
      link: 'https://app.zerolend.xyz',
    },
    {
      name: 'Immunefi',
      logo: immunefi,
      link: 'https://app.zerolend.xyz',
    },
  ],
  Investors: [
    {
      name: 'Blockchain Founders',
      logo: blockchainFounders,
      link: '',
    },
    {
      name: 'Momentum',
      logo: momentum,
      link: '',
    },
    {
      name: 'Morning Star',
      logo: morningStar,
      link: '',
    },
    {
      name: 'Crypto Banter',
      logo: cryptoBanter,
      link: '',
    },
    {
      name: 'Newman Capital',
      logo: newman,
      link: '',
    },
    {
      name: 'dV/dT',
      logo: dvdt,
      link: '',
    },
    {
      name: 'Transform Capital',
      logo: transformCapital,
      link: '',
    },
    {
      name: 'Cypher Capital',
      logo: cypherCapital,
      link: '',
    },
    {
      name: 'Bison Fund',
      logo: bisonFund,
      link: '',
    },
   /* {
      name: 'BDE Ventures',
      logo: bisonFund,
      link: '',
    },*/



   /* {
      name: 'Box Mining',
      logo: boxMining,
      link: '',
    },
    {
      name: 'Ethernity',
      logo: ethernity,
      link: '',
    },
    {
      name: 'Founder Heads',
      logo: founderHeads,
      link: '',
    },
    {
      name: 'Golden Tree',
      logo: GoldenTree,
      link: '',
    },
    {
      name: 'iAngels',
      logo: iangels,
      link: '',
    },

    {
      name: 'Krypital',
      logo: krypital,
      link: '',
    },

    {
      name: 'Ozaru',
      logo: ozaru,
      link: '',
    },

    {
      name: 'Via BTC Capital',
      logo: viaBTCCapital,
      link: '',
    },
    {
      name: 'WoodStock',
      logo: woodStock,
      link: '',
    },*/
  ],
};

let all: IIndividualPartner[] = [];
Object.values(partners).forEach((data) => {
  all = all.concat(data);
});

partners['All'] = all;

const Partners = () => {
  const [selectedType, setSelectedType] = useState<string>(
    Object.keys(partners)[0],
  );

  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');

  const typesData: IIndividualPartner[] = useMemo(() => {
    // @ts-ignore
    return partners[selectedType];
  }, [selectedType]);

  return (
    <Box pb={40}>
      <Container>
        <Text fontSize={28} fontWeight={600} textAlign={'center'} mb={2}>
          Partners and Collaborations
        </Text>
        <Text fontSize={20} opacity={0.6} textAlign={'center'} mb={6}>
          A growing ecosystem of DApps to simplify the DeFi lending experience
        </Text>
        <Box
          p={3}
          borderRadius={'42px'}
          display={'flex'}
          justifyContent={'center'}
          w={{ base: '100%', md: 'max-content' }}
          mx={'auto'}
          className={'theme-box-shadow'}
          bg={'black'}
          position={'relative'}
          zIndex={10}
          maxW={'100vw'}
          overflowX={'scroll'}
          flexWrap={{ base: 'wrap', md: 'unset' }}
        >
          {Object.keys(partners).map((key, index) => {
            return (
              <Box>
                <Box
                  bg={key === selectedType ? 'white' : 'transparent'}
                  py={2}
                  px={6}
                  mx={2}
                  my={{ base: 2, md: 0 }}
                  w={'max-content'}
                  color={key === selectedType ? 'black' : 'white'}
                  borderRadius={'20px'}
                  cursor={'pointer'}
                  key={index}
                  opacity={key === selectedType ? 1 : 0.6}
                  onClick={() => setSelectedType(key)}
                >
                  {key}
                </Box>
              </Box>
            );
          })}
        </Box>

        {isLargerThan1280 ? (
          <DesktopPartners data={typesData} />
        ) : (
          <MobilePartners data={typesData} />
        )}
      </Container>
    </Box>
  );
};

export default Partners;
