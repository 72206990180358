import { useEffect, useState } from 'react';

const useGetMetrics = () => {
  const [marketSize, setMarketSize] = useState<number>(34690000);
  const [tvl, setTVL] = useState<number>(22740000);
  const [uniqueAddress, setUniqueAddress] = useState<number>(160000);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const url = `https://api.zerolend.xyz`;
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,POST,OPTIONS',
      'Access-Control-Allow-Headers':
        'access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,content-type',
    };

    fetch(url, { headers })
      .then((res) => res.json())
      .then((res: {data: {marketSize: number, tvl: number, unique_addresses: number}}) => {
        const _v = res?.data || {};
        const marketSize = _v?.marketSize;
        const tvl = _v?.tvl;
        const _uniqueAddress = _v?.unique_addresses;

        if (marketSize) {
          setMarketSize(marketSize);
        }
        if (tvl) {
          setTVL(tvl);
        }
        if (_uniqueAddress) {
          setUniqueAddress(_uniqueAddress);
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log("e", err);
        setLoading(false);
      });
  }, []);

  return { loading, marketSize, tvl, uniqueAddress };
};

export default useGetMetrics;
